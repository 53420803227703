import '../css/index.css';
import tweetnacl from 'tweetnacl';
import b32_decode from 'base32-decode';

const bgs = {
	"bg-a.jpeg": "A person is performing a parkour jump in an indoor gym, leaping over padded obstacles with a red-covered floor and a wall behind.",
	"./bg-b.jpeg": "A person is mid-air while jumping over padded obstacles set up i./n an indoor gym with red flooring.",
	"./bg-c.jpeg": "A person balancing upside-down next to a blue gym mat",
	"./bg-d.jpeg": "A person jumping onto a bar between 2 towers",
	"./bg-e.jpeg": "A person jumping onto a bar between 2 towers - side view",
	"./bg-f.jpeg": "Two children upside down doing bridges off of a red block"
};

window.hasfired = false;
window.addEventListener("load", () => {
	console.log(`DEBUG:START hasfired`, window.hasfired);
	if(window.hasfired) return;
	window.hasfired = true;
	const el = document.querySelector("a.action-button.join");
	const source = el.getAttribute(`href`).replace(/^.\/|\/$/g, ``).toUpperCase();
	
	const tdec = new TextDecoder();
	const decoded = b32_decode(source, `RFC4648`);
	const decrypted = tweetnacl.secretbox.open(
		new Uint8Array(decoded),
		new Uint8Array([159, 24, 202, 200, 172, 3, 196, 105, 60, 3, 97, 155, 132, 9, 250, 14, 33, 47, 253, 164, 137, 87, 54, 5]),
		new Uint8Array([169, 159, 43, 213, 35, 41, 168, 112, 27, 69, 187, 35, 64, 237, 93, 28, 225, 9, 205, 58, 231, 44, 179, 202, 183, 229, 190, 104, 174, 16, 99, 17])
	);	
	
	el.href = `mailto:${tdec.decode(decrypted)}`;
});
