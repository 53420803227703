import '../css/index.css';
import tweetnacl from 'tweetnacl';

const bgs = {
	"bg-a.jpeg": "A person is performing a parkour jump in an indoor gym, leaping over padded obstacles with a red-covered floor and a wall behind.",
	"./bg-b.jpeg": "A person is mid-air while jumping over padded obstacles set up i./n an indoor gym with red flooring.",
	"./bg-c.jpeg": "A person balancing upside-down next to a blue gym mat",
	"./bg-d.jpeg": "A person jumping onto a bar between 2 towers",
	"./bg-e.jpeg": "A person jumping onto a bar between 2 towers - side view",
	"./bg-f.jpeg": "Two children upside down doing bridges off of a red block"
};

window.hasfired = false;
window.addEventListener("load", () => {
	console.log(`DEBUG:START hasfired`, window.hasfired);
	if(window.hasfired) return;
	window.hasfired = true;
	const el = document.querySelector("a.action-button.join");
	const source = el.getAttribute(`href`).replace(/^.\/|\/$/g, ``).toUpperCase();
	
	const tdec = new TextDecoder();
	const decoded = b32_decode(source, `RFC4648`);
	const decrypted = tweetnacl.secretbox.open(
		new Uint8Array(decoded),
		new Uint8Array([51, 106, 19, 129, 220, 106, 209, 157, 187, 191, 242, 193, 58, 104, 231, 72, 235, 71, 46, 160, 167, 196, 104, 158]),
		new Uint8Array([161, 219, 69, 93, 60, 82, 5, 220, 223, 109, 137, 61, 124, 19, 166, 57, 227, 111, 198, 57, 207, 125, 6, 178, 147, 113, 65, 246, 205, 232, 131, 46])
	);	
	
	el.href = `mailto:${tdec.decode(decrypted)}`;
});



import b32_decode from 'base32-decode';
// const msg = `johanna.billingsley@gmail.com`;
// const source = "HCUCPSFPFFQMXHDGV2BDFWOPDOTE6W2JW5YIWGAKTFRPCKE2QR7DNO4T3O2KFQCIVS5FXZUX"; // Put this in your HTML
